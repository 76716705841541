import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../atoms/Icon'
import styles from './Repository.module.scss'

export default function Repository({ repo }) {
  const {
    name,
    full_name,
    description,
    html_url,
    homepage,
    stargazers_count
  } = repo

  // const isExternal = !full_name.includes('kremalicious')

  // // for blog & portfolio and if there's no homepage, use github url
  // // else use homepage field
  // const repoLink =
  //   name === 'blog' || name === 'portfolio' || !homepage || isExternal
  //     ? html_url
  //     : homepage

  const title = name.includes('http') ? <a href={html_url}>
    <Icon name="GitHub" /> GitHub
  </a> : name

  return (
    <div className={styles.repo}>
      <h1 className={styles.repoTitle}>
        {title}
      </h1>
      <p>{description}</p>
      {
        homepage &&
        <p className={styles.meta}>
          {
            <a href={homepage} target="_blank"><Icon name="Compass" /> View</a>
          }
        </p>
      }
      { 
        stargazers_count && 
        <a>
          <Icon name="Star" /> {stargazers_count}
        </a>
      }
    </div>
  )
}

Repository.propTypes = {
  repo: PropTypes.object.isRequired
}
