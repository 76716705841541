import React, { memo } from 'react'
import PropTypes from 'prop-types'

import Repository from '../molecules/Repository'
import styles from './Repositories.module.scss'

Repositories.propTypes = {
  repos: PropTypes.array
}

function Repositories({ repos }) {
  const sideProjects = []

  sideProjects.push({
    homepage: 'https://eft-ammo.com',
    name: 'EFT Reference and Builders',
    description: 'NoFoodAfterMidnight\'s Ammo and Armor Charts for Escape from Tarkov. Data is automatically pulled from a Google spreadsheet',
    stargazers_count: 'Launched on 17/12/2021. ~120k users/1M pageviews per month',
  })

  // sideProjects.push({
  //   homepage: 'https://leaksdown.com',
  //   name: 'leaksDOWN.',
  //   description: 'Automated Piracy Detection and DMCA Takedowns. Built in collaboration with https://apiplant.com',
  //   stargazers_count: 'Launched on 28/09/2021',
  // })

  sideProjects.push({
    homepage: 'https://multistream.gg',
    name: 'multistream.gg',
    description: 'Watch multiple Twitch streams on your screen.',
    stargazers_count: 'Launched on 10/03/2021. ~5000 users/mo (01/11/2021)',
  })

  sideProjects.push({
    homepage: 'https://ezyou.vercel.app/',
    name: 'ezyou.shop',
    description: 'A simple ecommerce solution to bootstrap your online shop in a minute.',
    stargazers_count: 'Launched on 04/03/2021',
  })

  sideProjects.push({
    name: 'nicolagenesin.com',
    description: 'Built with GatsbyJS, each page served is server side rendered. Running in a docker container, this instance is hosted on a 1core/1GB machine alongside a few other websites, all exposing port 80 on a dedicated docker network. Nginx acts as reverse proxy to map each request to the correct docker container.',
  })

  sideProjects.push({
    homepage: 'https://i.imgur.com/raEhIA8.png',
    name: 'Rosetta@Home',
    description: 'I\'ve been a BOINC contributor since 2009, with a network of up to 15 machines. As a recent example, the promising results against COVID-19 (https://bit.ly/2TM82OR) proved once again how this is worth being part of. Want to help? you can use this https://github.com/NicolaGenesin/Dockerized-Boinc-Runner to join us in minutes!'
  })

  sideProjects.push({
    name: 'Dealoo',
    description: 'A bot that periodically grabs the latest deals from Amazon and posts them to Telegram, Facebook, Wordpress and Discord pages/channels.',
    stargazers_count: '~1000 users',
  })

  sideProjects.push({
    name: 'route66.rocks',
    description: 'I\'ll do the Route 66 in 2020. This is a simple map made in ~4 hours to show a few points of interest I want to visit! All data is sourced from Github.'
  })

  sideProjects.push({
    homepage: 'https://youtu.be/6Z2w86xXeOY',
    name: '(Bluetooth) Music player',
    description: 'Android app communicating through BT to a dockerized flask app running on a rpi2. Used to play the music I have on my local network without the need of internet access.'
  })

  sideProjects.push({
    name: 'FlightScanner',
    stargazers_count: '~300000 downloads',
    description: 'Android app and a high performane Express & Redis backend to track airplanes in realtime thanks to an open ADS-B network. Shut down by Google for unknown reasons. Rebuilt in React Native, but never published.'
  })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/Raspberry-DHT22',
    name: 'Temp Monitor',
    description: 'A DHT-22 sensor coupled with a raspberry, Grafana + InfluxDB and a little python script that helps me monitor my home temperature.'
  })

  sideProjects.push({
    name: 'Procedural city',
    description: 'An attempt to procedurally generate a city in Unity3D, with mono. Taking inspiration from the demoscene, see "Farbrausch - fr-041: debris".'
  })

  // sideProjects.push({
  //   name: 'ihopeyourday.rocks',
  //   homepage: 'https://ihopeyourday.rocks/',
  //   description: 'A super simple, bright static page and a Mailchimp account. A way to keep in contact with friends by sending them emails based on their preference.',
  //   stargazers_count: '~120 subscribers',
  // })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/ETH-ETC-Proxy-Miner-GUI',
    name: 'Ethereum Miner (Proxy)',
    description: 'A WPF form program that enables you to run an ETC/ETH gpu miner with minimal configuration.'
  })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/Leap-Motion-Hands-Tracking',
    name: 'Leap Motion DJ',
    description: 'Prototype built with Unity, C# and a Leap Motion. Choose your virtual vinyl and scratch it! Turntable and vinyl models created by Nicola Berti.'
  })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/SimpleBitcoin',
    name: 'SimpleBitcoin',
    description: 'An android app that tracks the bitcoin performances (thanks to blockchain.info\'s api) and plots them on a graph.'
  })

  sideProjects.push({
    stargazers_count: '~75000 downloads',
    name: 'Jamendo Client',
    description: 'Android app that lets you download (royalty-free) music and listen to it on your phone. Shut down by Google for unknown reasons.'
  })

  sideProjects.push({
    homepage: 'https://youtu.be/6Z2w86xXeOY',
    name: 'Squelch',
    stargazers_count: '~180000 downloads',
    description: 'Android music player and Flask backend to enrich local songs with metadata taken from several open source databases. Shut down by Google for unknown reasons.'
  })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/Mobile-ContinuousDeploy',
    name: 'Mobile CD',
    description: 'Made in a few hours, this simple tool deploys your apk (android) or zip (ios) artifacts to Appetize.io (mobile web emulator). Simple helper to any CI pipeline. After an artifact has been built, a file watcher will notice the change and push an update request to the web emulator.'
  })

  sideProjects.push({
    name: 'AirWalls',
    homepage: 'https://youtu.be/IqeJGK1y6Pc',
    stargazers_count: '~12000 downloads',
    description: 'Android app and a Express backend to search imgur images, using imugur APIs. Shut down by Google for unknown reasons.'
  })

  sideProjects.push({
    name: 'GlyphChat',
    description: 'Whatsapp clone prototype that uses glyphs instead of latin characters.',
    stargazers_count: 'Not published',
  })

  sideProjects.push({
    homepage: 'https://github.com/NicolaGenesin/Heavy_Rain',
    name: 'Heavy Rain',
    description: 'A single button app that plays a rain sound I\'ve recorded at home. Built in React Native.'
  })

  sideProjects.push({
    stargazers_count: '~20000 downloads',
    name: 'Camera Toy',
    description: 'Before instagram was mainstream, I made this android app to add cartoonish features (e.g. glasses) on top of facial features, in real-time, through your phone camera. Built thanks to Android NDK/SDK and the HAAR-like classifier provided by OpenCV.'
  })

  sideProjects.push({
    stargazers_count: 'University Project',
    name: '3D Modeler',
    description: 'A 3D modeler built in C++/OpenGL thanks to Qt Libraries and Blender. You can import .3ds, .xml and .obj models, transform them (scale, rotate, translate), apply lights of different nature and change textures.'
  })

  return (
    <section className={styles.section}>
      <h1 className={styles.sectionTitle}>Side Projects</h1>
      <div className={styles.repos}>
        {sideProjects.map(side => (
          <Repository key={side.name} repo={side} />
        ))}
      </div>
    </section>
  )
}

export default memo(Repositories)
